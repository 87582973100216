//
// 自定义Carousel
// --------------------------------------------------
.carousel {
  position: relative;
}
.web{
  .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;

  > .item {
    display: none;
    position: relative;
    .transition(.6s ease-in-out left);
    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
      .transition-transform(~'0.6s ease-in-out');
      .backface-visibility(~'hidden');
      .perspective(1000px);

      &.next,
      &.active.right {
        .translate3d(0, 100%, 0);
        left: 0;
      }
      &.prev,
      &.active.left {
        .translate3d(0, -100%, 0);
        left: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        .translate3d(0, 0, 0);
        left: 0;
      }
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }
}
}
.iphone{
  .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;

  > .item {
    display: none;
    position: relative;
    .transition(.6s ease-in-out left);
    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
      .transition-transform(~'0.6s ease-in-out');
      .backface-visibility(~'hidden');
      .perspective(1000px);

      &.next,
      &.active.right {
        .translate3d(100%, 0, 0);
        left: 0;
      }
      &.prev,
      &.active.left {
        .translate3d(-100%, 0, 0);
        left: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        .translate3d(0, 0, 0);
        left: 0;
      }
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }
}
}

// Left/right controls for nav
// ---------------------------

.carousel-control {
  display: inline-block;
  width: 50%;
  height: 100%;
  float: left;
  // Hover/focus state
  &:hover,
  &:focus {
    outline: 0;
    color: @carousel-control-color;
    text-decoration: none;
    .opacity(.9);
  }

  // Toggles
  .icon-prev,
  .icon-next,
  .glyphicon-chevron-left,
  .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
  }
  .icon-prev,
  .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
  }
  .icon-next,
  .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
  }
  .icon-prev,
  .icon-next {
    width:  20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
  }
}

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  margin-top:100px;
  padding:0;
  li {
    display: block;
  }
  .active {
    margin: 0;

  }
}